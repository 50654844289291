import * as React from "react"

// markup
const IndexPage = () => {
  return (
    <main>
      <title>Home Page</title>
      <h1>Paul Chappell</h1>
      <p>TODO</p>
    </main>
  )
}

export default IndexPage
